import React from "react";

function Footer() {
	//console.clear()
    return (
     <>     
        <footer className="footer-area pt-85">
				<div className="container">
					<div className="row">
						
						<div className="col-lg-3 col-sm-6">
							
							<div className="widget quick-link-widget">
								<h3 className="wow fadeInLeft">About</h3>
								<ul>
									<li className="wow fadeInLeft"><a href="/"><i className="fal fa-angle-right"></i>Home</a></li>
									<li className="wow fadeInLeft"><a href="/story"><i className="fal fa-angle-right"></i>Story</a></li>
									<li className="wow fadeInLeft"><a href="/aim"><i className="fal fa-angle-right"></i>Aim</a></li>
									<li className="wow fadeInLeft"><a href="/campus"><i className="fal fa-angle-right"></i>Campus</a></li>
									<li className="wow fadeInLeft"><a href="/Organisation"><i className="fal fa-angle-right"></i>Organisation</a></li>
								</ul>
							</div>
							
						</div><div className="col-lg-3 col-sm-6">
							
							<div className="widget quick-link-widget">
								<h3 className="wow fadeInLeft">Courses</h3>
								<ul>
									<li className="wow fadeInLeft"><a href="/Mountaineering-courses"><i className="fal fa-angle-right"></i>Mountaineering</a></li>
									<li className="wow fadeInLeft"><a href="/mtb-courses"><i className="fal fa-angle-right"></i>Mountain Terrain Biking</a></li>
									<li className="wow fadeInLeft"><a href="/scuba-diving-courses"><i className="fal fa-angle-right"></i>Scuba Diving</a></li>
									<li className="wow fadeInLeft"><a href="/rafting-courses"><i className="fal fa-angle-right"></i>White Water Rafting</a></li>
									<li className="wow fadeInLeft"><a href="/paragliding-courses"><i className="fal fa-angle-right"></i>Paragliding</a></li>
									<li className="wow fadeInLeft"><a href="/paramotor-courses"><i className="fal fa-angle-right"></i>Paramotor</a></li>
									<li className="wow fadeInLeft"><a href="/news-and-events"><i className="fal fa-angle-right"></i>News & Media</a></li>
								</ul>
							</div>
							
						</div>
						
						<div className="col-lg-3 col-sm-6">
							
							<div className="widget quick-destinations">
								<h3 className="wow fadeInLeft">Quick Links</h3>
								<ul>
									<li className="wow fadeInLeft"><a href="/faq"><i className="fal fa-angle-right"></i>FAQ</a></li>
									<li className="wow fadeInLeft"><a href="/downloads"><i className="fal fa-angle-right"></i>Downloads</a></li>
									<li className="wow fadeInLeft"><a href="/contact-us"><i className="fal fa-angle-right"></i>Contact Us</a></li>
									<li className="wow fadeInLeft"><a href="/privacy-policy"><i className="fal fa-angle-right"></i>Privacy Policy</a></li>
									<li className="wow fadeInLeft"><a href="/terms-and-conditions"><i className="fal fa-angle-right"></i>Terms & Conditions</a></li>
									<li className="wow fadeInLeft"><a href="/nimas-admin/login" target="_blank"><i className="fal fa-angle-right"></i>Nimas Admin Login</a></li>
									<li className="wow fadeInLeft"><a href="http://crmleads.erpthemes.com/" target="_blank"><i className="fal fa-angle-right"></i>CRM Admin Login</a></li>
								</ul>
							</div>
							
						</div>
						<div className="col-lg-3 col-sm-6">
							
							<div className="widget contact-us cont contact-widget">
								<h3 className="wow fadeInLeft">Contact Us</h3>
								<ul className="cont-info">
									
									<li className="wow fadeInLeft"><a href="#"><i className="fal fa-envelope"></i> info@nimasdirang.com</a></li>
									<li className="wow fadeInLeft"><a href="#"><i className="fal fa-phone-alt"></i> General Query: +91-9774731855 </a></li>
									<li className="wow fadeInLeft"><a href="#"><i className="fal fa-phone-alt"></i> Technical : +91-8303026265</a></li>
									
								</ul>
								<ul className="social">
									<li className="wow fadeInLeft"><a href="https://www.facebook.com/nimas2018" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
									<li className="wow fadeInLeft"><a href="https://twitter.com/dirangnimas?lang=en" target="_blank"><i className="fab fa-twitter"></i></a></li>
									<li className="wow fadeInLeft"><a href="https://www.instagram.com/explore/locations/1702147523365664/nimas-dirang/?hl=en" target="_blank"><i className="fab fa-instagram"></i></a></li>
									
									<li className="wow fadeInLeft"><a href="https://www.youtube.com/results?search_query=NIMAS+Dirang+Arunachal+Pradesh" target="_blank"><i className="fab fa-youtube"></i></a></li>
								</ul>
							</div>
							
						</div>
					</div>
				</div>	
			<div className="copy-right-area">
				<div className="container">
					<div className="row">
						<div className="col-sm-12">
							
							<div className="copyright-text text-center">
								<p>© 2023 nimasdirang.com. All rights reserved.</p>
							</div>
							
						</div>
					</div>
				</div>
			</div>
			</footer>
			
		
			

     </>
    );
  }
  
  export default Footer;
  